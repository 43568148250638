import { Routes, Route } from "react-router-dom";
import Login from "./views/commons/Login";
import SideBar from "./components/SideBar";
import Home from "./views/Dashboard/DashboardPage/Home";
import Temoignages from "./views/Dashboard/DashboardPage/Temoignages";
import Partenaires from "./views/Dashboard/DashboardPage/Partenaires";
import Membres from "./views/Dashboard/DashboardPage/Membres";
import Events from "./views/Dashboard/DashboardPage/Events";
import AddEvent from "./views/AddEvent/AddEvent";
import RequireAuth from './components/RequireAuth';
import Layout from './components/Layout';

function App() {
  return (
    <div className="min-h-screen w-full font-poppins">
     <Routes>
        <Route path="/" element={<Layout />}>
        
          <Route path="" element={<Login />}/>
          <Route path="/dashboard" element={<SideBar />}>
            <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
              <Route path="" element={<Home />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
              <Route path="/dashboard/events" element={<Events />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
              <Route path="/dashboard/events/AddEvent" element={<AddEvent />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
              <Route path="/dashboard/membres" element={<Membres />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
              <Route path="/dashboard/partenaires" element={<Partenaires />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
              <Route path="/dashboard/temoignages" element={<Temoignages />} />
            </Route>
          </Route>
          
        </Route>
     </Routes>

       
    </div>
  );
}

export default App;
