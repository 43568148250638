import axios from 'axios';
const BASE_URL = 'https://dev-api.dataafriquehub.org/';

export default axios.create({
  baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL
});
